//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useMilkQualityDeliveryStopStatusesStore = defineStore('milkQualityDeliveryStopStatusesStore', {
  state: () => ({
    milkQualityDeliveryStopStatuses: [] as any[]
  }),
  actions: {
    async fetchMilkQualityDeliveryStopStatuses() {
      if (this.milkQualityDeliveryStopStatuses.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/deliveryStop')
          this.milkQualityDeliveryStopStatuses = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
