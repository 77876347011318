<template>
  <div class="container" id="milkqualities_edit">
    <h1 v-html="$t('milkqualities_edit_title')"></h1>
    <!-- {{ milkQualityDetails.milkQualitySampleType }} -->
    <!-- Sichtbarkeit anhand Typ und Privilege:
          Typ (milkQualitySampleType)
          MP=QUALITY
          GH=CONTENT
          KQ=MILK_COLLECTION_POINT
          MW=MONTHLY
        Privilege
          ct=milkQualitySamples/content/read -->
    <v-form ref="form" @submit.prevent="">
      <v-row>
        <v-col cols="6">
          <!-- Allgemein (Person/Betrieb) -->
          <h5 v-html="$t('milkqualities_edit_general_person')"></h5>
          <v-row>
            <!-- AGIS-ID Betrieb -->
            <v-col cols="3">
              <v-text-field
                density="compact"
                id="milkqualities_edit_general_person_agis_id_company"
                v-model="milkQualityDetails.milkQualityFarmAgisId"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_person_agis_id_company')"
            /></v-col>
            <!-- Vorname -->
            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_person_name"
                v-model="milkQualityDetails.milkQualityFarmName1"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_person_name')"
            /></v-col>
            <!-- Name -->
            <v-col cols="3">
              <v-text-field
                density="compact"
                id="milkqualities_edit_general_person_surname"
                v-model="milkQualityDetails.milkQualityFarmName2"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_person_surname')"
            /></v-col>
            <!-- Adresse -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_person_address"
                v-model="milkQualityDetails.milkQualityFarmAddress1"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_person_address')"
            /></v-col>

            <!-- PLZ -->

            <v-col cols="3"
              ><v-text-field
                dense
                id="milkqualities_edit_general_person_zip_code"
                v-model="milkQualityDetails.milkQualityFarmAddressZip"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_person_zip_code')"
            /></v-col>

            <!-- Ort -->

            <v-col cols="3">
              <v-text-field
                dense
                id="milkqualities_edit_general_person_place"
                v-model="milkQualityDetails.milkQualityFarmAddressLocality"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_person_place')"
            /></v-col>

            <!-- Telefon 1 -->
            <v-col cols="3">
              <v-text-field
                density="compact"
                id="milkqualities_edit_general_person_telephone_1"
                v-model="milkQualityDetails.milkQualityFarmPhone1"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_person_telephone_1')"
            /></v-col>

            <!-- Telefon 2 -->
            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_person_telephone_2"
                v-model="milkQualityDetails.milkQualityFarmPhone2"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_person_telephone_2')"
            /></v-col>

            <!-- E-Mail -->

            <v-col cols="3">
              <v-text-field
                density="compact"
                id="milkqualities_edit_general_person_email"
                v-model="milkQualityDetails.milkQualityFarmEmail"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_person_email')"
            /></v-col>

            <!-- Kanton -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_person_canton"
                v-model="milkQualityDetails.canton"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_person_canton')"
            /></v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <!-- Allgemein (Probe) -->
          <h5 v-html="$t('milkqualities_edit_general_sample')"></h5>
          <v-row>
            <!-- Probetyp  -->

            <v-col cols="3">
              <v-select
                id="milkqualities_edit_general_sample_control_type"
                v-model="milkQualityDetails.milkQualitySampleType"
                :label="$t('milkqualities_edit_general_sample_control_type')"
                disabled
                :items="milkQualitySampleTypes"
                :item-title="this.$getLangKey()"
                item-value="name"
              />
            </v-col>
            <!-- Tierart  -->

            <v-col cols="3">
              <v-select
                id="milkqualities_edit_general_sample_animal_type"
                v-model="milkQualityDetails.animalTypeId"
                :label="$t('milkqualities_edit_general_sample_animal_type')"
                disabled
                :items="animalTypes"
                :item-title="this.$getLangKey()"
                item-value="id"
              />
            </v-col>

            <!-- ID -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_id"
                v-model="milkQualityDetails.id"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_id')"
            /></v-col>

            <!-- Probeart -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_sample_procedure"
                v-model="milkQualityDetails.typeCodeLab"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_sample_procedure')"
            /></v-col>

            <!-- Referenz Labor -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_lab_reference"
                v-model="milkQualityDetails.primaryKeyLab"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_lab_reference')"
            /></v-col>

            <!-- Periode -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_period"
                v-model="milkQualityDetails.period"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_period')"
            /></v-col>

            <!-- Datum Probeentnahme -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_sample_collection_date"
                v-model="milkQualityDetails.formattedSampleDate"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_sample_collection_date')"
            /></v-col>

            <!-- Datum Untersuchung -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_smple_analisys_date"
                v-model="milkQualityDetails.formattedAnalysisDate"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_smple_analisys_date')"
            /></v-col>

            <!-- Grenzwert -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_limit_value"
                v-model="milkQualityDetails.thresholdExeededLab"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_limit_value')"
            /></v-col>

            <!-- Fehlercode -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_error_code"
                v-model="milkQualityDetails.errorCodeLab"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_error_code')"
            /></v-col>

            <!-- Infotext  -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_info_text"
                v-model="milkQualityDetails.updateInfo"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_info_text')"
            /></v-col>

            <!-- Probenstatus  -->

            <v-col cols="3">
              <v-select
                id="milkqualities_edit_general_sample_sample_status"
                v-model="milkQualityDetails.sampleStatus"
                :label="$t('milkqualities_edit_general_sample_sample_status')"
                disabled
                :items="sampleStatuses"
                :item-title="this.$getLangKey()"
                item-value="value"
              />
            </v-col>

            <!-- Status  -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_status"
                v-model="milkQualityDetails.labState"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_status')"
            /></v-col>

            <!-- Block-ID  MP/GH/KQ-->

            <v-col
              cols="3"
              v-if="
                milkQualityDetails.milkQualitySampleType == 'QUALITY' ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_general_block_id"
                v-model="milkQualityDetails.transponderNo"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_general_block_id')"
            /></v-col>

            <!-- Produzent (Nr.)  MP/GH/KQ -->

            <v-col
              cols="3"
              v-if="
                milkQualityDetails.milkQualitySampleType == 'QUALITY' ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_general_manufacturer_number"
                v-model="milkQualityDetails.labIdentProducer"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_general_manufacturer_number')"
            /></v-col>

            <!-- Genossenschaft MP/GH/KQ -->

            <v-col
              cols="3"
              v-if="
                milkQualityDetails.milkQualitySampleType == 'QUALITY' ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_general_association"
                v-model="milkQualityDetails.labIdentCoop"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_general_association')"
            /></v-col>

            <!-- MBH-Ident MP/GH/KQ -->

            <v-col
              cols="3"
              v-if="
                milkQualityDetails.milkQualitySampleType == 'QUALITY' ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_general_mbh_ident"
                v-model="milkQualityDetails.dairyIdent"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_general_mbh_ident')"
            /></v-col>

            <!-- Flaschennummer  MP/GH/KQ-->

            <v-col
              cols="3"
              v-if="
                milkQualityDetails.milkQualitySampleType == 'QUALITY' ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_general_bottle_number"
                v-model="milkQualityDetails.bottleNo"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_general_bottle_number')"
            /></v-col>

            <!-- Relevant*  MP/GH/KQ-->

            <v-col
              cols="3"
              v-if="
                milkQualityDetails.milkQualitySampleType == 'QUALITY' ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_general_relevant"
                v-model="milkQualityDetails.relevantForRatingLab"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_general_relevant')"
            /></v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          <!-- Beanstandungen (öffentlich-rechtlich) Untertitel für MW -->
          <div v-if="milkQualityDetails.milkQualitySampleType == 'MONTHLY'">
            <h6 v-html="$t('milkqualities_edit_subtitle_month_value_complaint')"></h6>
          </div>
          <!-- Beanstandungen (privatrechtlich) Untertitel für MP -->
          <div v-if="milkQualityDetails.milkQualitySampleType == 'QUALITY'">
            <h6 v-html="$t('milkqualities_edit_subtitle_single_results_private_complaints')"></h6>
          </div>
          <v-row>
            <!-- Beanstandungen Keimzahl (Anz.)* MP/MW-->

            <v-col cols="3" v-if="milkQualityDetails.milkQualitySampleType == 'MONTHLY' || milkQualityDetails.milkQualitySampleType == 'QUALITY'"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_month_value_complaint_germs"
                v-model="milkQualityDetails.complaintsGerms"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_month_value_complaint_germs')"
            /></v-col>

            <!-- Beanstandungen Zellzahl (Anz.)*  MP/MW-->

            <v-col cols="3" v-if="milkQualityDetails.milkQualitySampleType == 'MONTHLY' || milkQualityDetails.milkQualitySampleType == 'QUALITY'"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_month_value_complaint_cells"
                v-model="milkQualityDetails.complaintsCells"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_month_value_complaint_cells')"
            /></v-col>

            <!-- Beanstandungen Hemmstoff (Anz.)* MP/MW-->

            <v-col cols="3" v-if="milkQualityDetails.milkQualitySampleType == 'MONTHLY' || milkQualityDetails.milkQualitySampleType == 'QUALITY'"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_month_value_complaint_inhibitors"
                v-model="milkQualityDetails.complaintsInhibitor"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_month_value_complaint_inhibitors')"
            /></v-col>

            <!-- Beanstandungen Gefrierpunkt (Anz.)* MP-->

            <v-col cols="3" v-if="milkQualityDetails.milkQualitySampleType == 'QUALITY'">
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_private_complaints_freezing_point"
                v-model="milkQualityDetails.complaintsFreezing"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_private_complaints_freezing_point')"
              />
            </v-col>

            <!-- Liefersperre  store MP/MW -->

            <v-col cols="3" v-if="milkQualityDetails.milkQualitySampleType == 'MONTHLY' || milkQualityDetails.milkQualitySampleType == 'QUALITY'">
              <v-select
                id="milkqualities_edit_subtitle_month_value_complaint_delivery_forbidden"
                v-model="milkQualityDetails.deliveryStopStatus"
                :label="$t('milkqualities_edit_subtitle_month_value_complaint_delivery_forbidden')"
                disabled
                :items="milkQualityDeliveryStopStatuses"
                :item-title="this.$getLangKey()"
                item-value="name"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" v-if="milkQualityDetails.milkQualitySampleType == 'QUALITY'">
          <!-- Beanstandung (öffentlich-rechtlich) MP für den ganzen Untertitel aber im Doku steht ?-->
          <h6 v-html="$t('milkqualities_edit_subtitle_single_results_public_complaints')"></h6>
          <!-- Liefersperre  store -->
          <v-row>
            <v-col cols="3">
              <v-select
                id="milkqualities_edit_subtitle_single_results_public_complaints_delivery_forbidden"
                v-model="milkQualityDetails.deliveryStopStatus"
                :label="$t('milkqualities_edit_subtitle_single_results_public_complaints_delivery_forbidden')"
                disabled
                :items="milkQualityDeliveryStopStatuses"
                :item-title="this.$getLangKey()"
                item-value="name"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" v-if="milkQualityDetails.milkQualitySampleType == 'QUALITY'">
          <!-- Zuschläge / Abzüge MP für den ganzen Untertitel -->
          <h6 v-html="$t('milkqualities_edit_subtitle_single_results_addition_deduction')"></h6>
          <v-row>
            <!-- Abzug Keimzahl (Rp.)  -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_addition_deduction_germs"
                v-model="milkQualityDetails.deductibleGerms"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_addition_deduction_germs')"
            /></v-col>

            <!-- Abzug Zellzahl (Rp.)  -->

            <v-col cols="3">
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_addition_deduction_cells"
                v-model="milkQualityDetails.deductibleCells"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_addition_deduction_cells')"
            /></v-col>

            <!-- Abzug Hemmstoff (Rp.)  -->

            <v-col cols="3">
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_addition_deduction_inhibitors"
                v-model="milkQualityDetails.deductibleInhibitor"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_addition_deduction_inhibitors')"
            /></v-col>

            <!-- Abzug Gefrierpunkt (Rp.)  -->

            <v-col cols="3">
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_addition_deduction_freezing_point"
                v-model="milkQualityDetails.deductibleFreezingPoint"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_addition_deduction_freezing_point')"
            /></v-col>

            <!-- Zuschlag (Rp.)  positiver Wert-->

            <v-col cols="3">
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_addition_deduction_addition"
                v-model="milkQualityDetails.deductibleTotalMonthly"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_addition_deduction_addition')"
            /></v-col>

            <!-- Abzug/Zuschlag Total (Rp.) negativer Wert -->

            <v-col cols="3">
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_addition_deduction_total_addition_deduction"
                v-model="milkQualityDetails.deductibleTotalMonthly"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_addition_deduction_total_addition_deduction')"
            /></v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          <!-- Ergebnisse sottotitolo -->
          <h6 v-html="$t('milkqualities_edit_subtitle_single_results_results')"></h6>
          <v-row>
            <!-- Keimzahl/ml  MP/GH?/KQ/MW-->

            <v-col
              cols="3"
              v-if="
                milkQualityDetails.milkQualitySampleType == 'QUALITY' ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT' ||
                milkQualityDetails.milkQualitySampleType == 'MONTHLY'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_germs_ml"
                v-model="milkQualityDetails.germs"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_germs_ml')"
            /></v-col>

            <!-- Zellzahl/ml  MP/GH/KQ/MW -->

            <v-col
              cols="3"
              v-if="
                milkQualityDetails.milkQualitySampleType == 'QUALITY' ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT' ||
                milkQualityDetails.milkQualitySampleType == 'MONTHLY'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_cells_ml"
                v-model="milkQualityDetails.cells"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_cells_ml')"
            /></v-col>

            <!-- Hemmstoff   MP/GH?/KQ/MW -->

            <v-col
              cols="3"
              v-if="
                milkQualityDetails.milkQualitySampleType == 'QUALITY' ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT' ||
                milkQualityDetails.milkQualitySampleType == 'MONTHLY'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_inhibitors"
                v-model="milkQualityDetails.inhibitor"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_inhibitors')"
            /></v-col>

            <!-- Gefrierpunkt (°C)   MP/GH/KQ, ct -->

            <v-col
              cols="3"
              v-if="
                ($privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) &&
                  milkQualityDetails.milkQualitySampleType == 'QUALITY') ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_freezing_point"
                v-model="milkQualityDetails.freezingPoint"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_freezing_point')"
            /></v-col>

            <!-- Adaption GP (°C)  MP, ct-->

            <v-col
              cols="3"
              v-if="
                $privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) && milkQualityDetails.milkQualitySampleType == 'QUALITY'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_adaption"
                v-model="milkQualityDetails.milkQualityRatingAdaptionGp"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_adaption')"
            /></v-col>

            <!-- Q75plus (°C)  MP, ct-->

            <v-col
              cols="3"
              v-if="
                $privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) && milkQualityDetails.milkQualitySampleType == 'QUALITY'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_q75_plus"
                v-model="milkQualityDetails.milkQualityRatingValueQ75"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_q75_plus')"
            /></v-col>

            <!-- Harnstoff (mg/100ml)   MP/GH/KQ, ct-->

            <v-col
              cols="3"
              v-if="
                ($privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) &&
                  milkQualityDetails.milkQualitySampleType == 'QUALITY') ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_urea"
                v-model="milkQualityDetails.urea"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_urea')"
            /></v-col>

            <!-- Fettgehalt (g/100g)   MP/GH/KQ, ct-->

            <v-col
              cols="3"
              v-if="
                ($privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) &&
                  milkQualityDetails.milkQualitySampleType == 'QUALITY') ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_fat"
                v-model="milkQualityDetails.fat"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_fat')"
            /></v-col>

            <!-- Eiweissgehalt (g/100g)   MP/GH/KQ, ct-->

            <v-col
              cols="3"
              v-if="
                ($privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) &&
                  milkQualityDetails.milkQualitySampleType == 'QUALITY') ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_protein"
                v-model="milkQualityDetails.protein"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_protein')"
            /></v-col>

            <!-- Freie Fettsäuren (mmol/10 kg)  MP/GH/KQ, ct -->

            <v-col
              cols="3"
              v-if="
                ($privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) &&
                  milkQualityDetails.milkQualitySampleType == 'QUALITY') ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_free_fatty_acids"
                v-model="milkQualityDetails.fattyAcid"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_free_fatty_acids')"
            /></v-col>

            <!-- Buttersäuresporen (Sporen/l)  MP/GH/KQ, ct-->

            <v-col
              cols="3"
              v-if="
                ($privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) &&
                  milkQualityDetails.milkQualitySampleType == 'QUALITY') ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_butter_spores"
                v-model="milkQualityDetails.clostridia"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_butter_spores')"
            /></v-col>

            <!-- Kasein (g/100g)  MP/GH/KQ, ct -->

            <v-col
              cols="3"
              v-if="
                ($privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) &&
                  milkQualityDetails.milkQualitySampleType == 'QUALITY') ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_casein"
                v-model="milkQualityDetails.casein"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_casein')"
            /></v-col>

            <!-- Kappa-Kasein B (mg/l)  MP/GH/KQ, ct-->

            <v-col
              cols="3"
              v-if="
                ($privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) &&
                  milkQualityDetails.milkQualitySampleType == 'QUALITY') ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_kappa_casein"
                v-model="milkQualityDetails.kappaCasein"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_kappa_casein')"
            /></v-col>

            <!-- g Kappa-Kasein B / kg Kasein  MP/GH/KQ, ct -->

            <v-col
              cols="3"
              v-if="
                ($privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) &&
                  milkQualityDetails.milkQualitySampleType == 'QUALITY') ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_kappa_casein_casein"
                v-model="milkQualityDetails.kappaCaseinBgKg"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_kappa_casein_casein')"
            /></v-col>

            <!-- Laktosegehalt (g/100g)  MP/GH/KQ, ct -->

            <v-col
              cols="3"
              v-if="
                ($privileges.has({
                  path: '/milkQualitySamples/content',
                  permission: 'read'
                }) &&
                  milkQualityDetails.milkQualitySampleType == 'QUALITY') ||
                milkQualityDetails.milkQualitySampleType == 'CONTENT' ||
                milkQualityDetails.milkQualitySampleType == 'MILK_COLLECTION_POINT'
              "
            >
              <v-text-field
                density="compact"
                id="milkqualities_edit_subtitle_single_results_results_lactose"
                v-model="milkQualityDetails.lactose"
                disabled
                type="text"
                :label="$t('milkqualities_edit_subtitle_single_results_results_lactose')"
            /></v-col>
            <!-- Erklärungen* TODO <br />
           <v-col cols="3"><v-text-field density="compact" id="milkqualities_edit_general_sample_explanations" v-model="milkQualityDetails." disabled type="text" :label="$t('milkqualities_edit_general_sample_explanations')" /></v-col>
         -->

            <!-- Erklärungen* TODO <br />
           <v-col cols="3"><v-text-field density="compact" id="milkqualities_edit_general_sample_explanations" v-model="milkQualityDetails." disabled type="text" :label="$t('milkqualities_edit_general_sample_explanations')" /></v-col>
         -->

            <!-- Erfasst: $Einlesedatum -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_inserted_loading_date"
                v-model="milkQualityDetails.formattedCreation"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_inserted_loading_date')"
            /></v-col>

            <!-- Mutiert: $Mutationsdatum -->

            <v-col cols="3"
              ><v-text-field
                density="compact"
                id="milkqualities_edit_general_sample_mutation_date"
                v-model="milkQualityDetails.formattedModification"
                disabled
                type="text"
                :label="$t('milkqualities_edit_general_sample_mutation_date')"
            /></v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          <!-- Protokoll -->
          <h5 v-html="$t('milkqualities_edit_protocol')"></h5>
          <v-row>
            <div v-if="milkQualityDetails.milkQualitySampleType == 'MONTHLY'">
              <div
                v-if="
                  milkQualityDetails.id &&
                  $privileges.has({
                    path: '/milkQualityRatingLabs',
                    permission: 'history'
                  })
                "
              >
                <History
                  v-if="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
                  :url="'/milkQualityRatingLabs/' + milkQualityDetails.id"
                  ref="history"
                />
              </div>
            </div>
            <div v-else>
              <div
                v-if="
                  milkQualityDetails.id &&
                  $privileges.has({
                    path: '/milkQualitySamples',
                    permission: 'history'
                  })
                "
              >
                <History
                  v-if="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
                  :url="'/milkQualitySamples/' + milkQualityDetails.id"
                  ref="history"
                />
              </div>
            </div>
          </v-row>
        </v-col>
        <v-col cols="6">
          <!-- SMS versenden button TODO -->

          <v-btn id="milkqualities_edit_general_sample_send_sms" color="secondary" class="shadow-none" @click="sendGeneralSampleSMS()"
            ><span v-html="$t('milkqualities_edit_general_sample_send_sms')" /></v-btn
          ><br />
          SMS-Versand:$Datum_Letzter_Versand TODO <br />
          <!-- 
<v-text-field density="compact" id="milkqualities_edit_general_sample_last_sms_sending_date" v-model="milkQualityDetails." disabled type="text" :label="$t('milkqualities_edit_general_sample_last_sms_sending_date')" />
-->
          $Mobile-Nr. TODO <br />
          <!--
<v-text-field density="compact" id="milkqualities_edit_general_sample_mobile_number" v-model="milkQualityDetails." disabled type="text" :label="$t('milkqualities_edit_general_sample_mobile_number')" />
-->
        </v-col>

        <v-row>
          <div class="aligned-buttons">
            <v-btn color="primary" class="shadow-none" @click="goBack()"><span v-html="$t('back')" /></v-btn>
          </div>
        </v-row>
      </v-row>
    </v-form>
  </div>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { showError } from '@/services/axios'

import History from '@/components/rdh/history'

const milkQualityDetailsMinimal = {}
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import { useMilkQualityDeliveryStopStatusesStore } from '@/store/enums/MilkQualityDeliveryStopStatusesStore'
import { useMilkQualitySampleTypesStore } from '@/store/enums/MilkQualitySampleTypesStore'
import { useSampleStatusStore } from '@/store/enums/SampleStatusStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'milkqualities_details',
  props: { sampleUrl: { String }, primaryKeyLab: { String } },
  components: {
    History: History
  },
  data() {
    return {
      milkQualityDetails: milkQualityDetailsMinimal
    }
  },
  computed: {
    milkQualitySampleTypes() {
      return useMilkQualitySampleTypesStore().milkQualitySampleTypes
    },
    animalTypes() {
      return useAnimalTypesStore().AnimalTypes
    },
    sampleStatuses() {
      return useSampleStatusStore().sampleStatuses
    },
    milkQualityDeliveryStopStatuses() {
      return useMilkQualityDeliveryStopStatusesStore().milkQualityDeliveryStopStatuses
    }
  },
  methods: {
    async loadMilkQuality() {
      try {
        const result = await this.axios.get(apiURL + '/milkQualities/' + this.sampleUrl + '/' + this.primaryKeyLab, {
          headers: { 'Content-Type': 'application/json' }
        })
        if (await result) {
          this.milkQualityDetails = result.data
          this.milkQualityDetails.formattedSampleDate = this.$formatChDate(result.data.sampleDate)
          this.milkQualityDetails.formattedAnalysisDate = this.$formatChDate(result.data.analysisDate)
          this.milkQualityDetails.formattedCreation = this.$formatChDate(result.data.creation)
          this.milkQualityDetails.formattedModification = this.$formatChDate(result.data.modification)
          //Versanddatum hier
          return result.data
        }
      } catch (e) {
        showError(e)
        console.log('e', e)
      }
    },

    goBack() {
      this.$router.push({ name: 'milkqualities_read' }).catch(() => {
        /* duplicated route */
      })
    },
    sendGeneralSampleSMS() {
      console.log('sendGeneralSampleSMS')
    },
    sendGatheringPointSampleSMS() {
      console.log('sendGatheringPointSampleSMS')
    }
  },
  beforeMount() {
    useAnimalTypesStore().fetchAnimalTypes()
    useMilkQualityDeliveryStopStatusesStore().fetchMilkQualityDeliveryStopStatuses()
    useMilkQualitySampleTypesStore().fetchMilkQualitySampleTypes()
    useSampleStatusStore().fetchSampleStatuses()
    this.loadMilkQuality()
  }
})
</script>
<style lang="scss" scoped>
.dateBox {
  margin: 1rem;
}
</style>
